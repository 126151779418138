import History from "../components/dashboard/History";
import Layout from "../components/Layout/Layout";

const HistoryPage = () => {
  return (
    <Layout>
      <div>
        <History />
      </div>
    </Layout>
  );
};
export default HistoryPage;
