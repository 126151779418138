import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    user: null,
    msalInstance: null
};

export const msalInstanceSlice = createSlice({
  name: 'msalInstance',
  initialState,
  reducers: {
    setMsalInstance: (state, action) => {
        state.msalInstance = action.payload;
        console.log("msal inst:", state.msalInstance);
    },
    setAuthStatus: (state, action) => {
        state.isAuth = action.payload.authStatus;
    },
    setUser: (state, action) => {
        state.user = action.payload;
        console.log("user ", state.user);
    }
  },
});

export const { setMsalInstance, setAuthStatus, setUser } = msalInstanceSlice.actions;

export default msalInstanceSlice.reducer;
