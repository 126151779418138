import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchTimeSettings,
  fetchPlanSettings,
} from "../../store/redux/slices/settingsSlice";
import {
  fetchCompletedUnitsToday,
  fetchUnitsHistory,
  fetchActiveUnits,
} from "../../store/redux/slices/unitsSlice";
import Paper from "@mui/material/Paper";
import Moment from "moment";
import "moment/locale/sl";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";

import "./Home.css";

const Home = () => {
  const settingsStatus = useSelector((state) => state.settings.status);
  const planSettings = useSelector((state) => state.settings.planSettings);
  const timeSettings = useSelector((state) => state.settings.timeSettings);
  const todayCompleted = useSelector((state) => state.units.todayCompleted);
  const unitStatus = useSelector((state) => state.units.historyStatus);
  const units = useSelector((state) => state.units.units);

  const dispatch = useDispatch();

  console.log(timeSettings);
  console.log(units);

  useEffect(() => {
    if (settingsStatus === "idle") {
      dispatch(fetchPlanSettings());
      dispatch(fetchTimeSettings());
    }
    if (unitStatus === "idle") {
      dispatch(fetchActiveUnits());
    }
    dispatch(fetchCompletedUnitsToday());
  }, [
    dispatch,
    fetchCompletedUnitsToday,
    fetchActiveUnits,
    fetchTimeSettings,
    fetchPlanSettings,
  ]);

  const getEstimatedTime = (esTime, startTime, endTime, workplace) => {
    let duration;

    let startShiftTime = Moment.utc(startTime);
    let endShiftTime = Moment.utc(endTime);
    let now = Moment.utc(new Date());

    if (endTime !== null) {
      duration = Moment.duration(endShiftTime.diff(startShiftTime));
    } else {
      duration = Moment.duration(now.diff(startShiftTime));
    }
    var estimate;

    timeSettings.forEach((element) => {
      if (workplace == 1 || workplace == 2) {
        if (element.dm == 1) {
          if (element.time < duration.asMinutes()) {
            estimate = true;
          } else {
            estimate = false;
          }
        }
      } else if (workplace == 3 || workplace == 4) {
        if (element.dm == 2) {
          if (element.time < duration.asMinutes()) {
            estimate = true;
          } else {
            estimate = false;
          }
        }
      } else if (workplace == 5 || workplace == 6) {
        if (element.dm == 3) {
          if (element.time < duration.asMinutes()) {
            estimate = true;
          } else {
            estimate = false;
          }
        }
      } else if (workplace == 7 || workplace == 8) {
        if (element.dm == 4) {
          if (element.time < duration.asMinutes()) {
            estimate = true;
          } else {
            estimate = false;
          }
        }
      } else if (workplace == 9 || workplace == 10) {
        if (element.dm == 5) {
          if (element.time < duration.asMinutes()) {
            estimate = true;
          } else {
            estimate = false;
          }
        }
      }
    });

    return estimate;
  };

  return (
    <div>
      <Grid container spacing={1} style={{ padding: "8px" }}>
        <Grid item xs={6}>
          <Card className="card-center">
            <div style={{ display: "flex" }}>
              {planSettings.map((item) => (
                <div key={item.name} style={{ margin: "0 20px", fontSize: "20px" }}>
                  <div>{item.name}</div>
                  <div>
                    <span
                      style={{
                        fontSize: "64px",
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.plan}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="card-center">
            <div>
              <div style={{fontSize: "20px"}}>Število dokončanih enot danes</div>
              <span style={{ fontSize: "64px", fontWeight: "700" }}>{todayCompleted}</span>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="card-normal">
            <TableContainer component={Paper} style={{ boxShadow: "none" }} className="data-table">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Enota</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DM1</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DM2</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DM3</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DM4</strong>
                    </TableCell>
                    <TableCell>
                      <strong>DM5</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {units.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.enota}
                      </TableCell>

                      {row.phases.map((row) => (
                        <TableCell key={row.startTime}>
                          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                              <div>
                                {Moment(row.startTime).format(
                                  "Do MMMM YYYY, HH:mm:ss"
                                )}
                              </div>
                              <div className={
                                getEstimatedTime(
                                  row.workplace,
                                  row.startTime,
                                  row.endTime,
                                  row.workplace
                                ) ? "red" : "green"
                              }>
                                {row.endTime != null
                                  ? Moment(row.endTime).format(
                                      "Do MMMM YYYY, HH:mm:ss"
                                    )
                                  : "-"}
                              </div>
                            </div>

                            {getEstimatedTime(
                              row.workplace,
                              row.startTime,
                              row.endTime,
                              row.workplace
                            ) ? (
                              <div
                                style={{
                                  background: "red",
                                  height: "15px",
                                  width: "15px",
                                  borderRadius: "50%"
                                }}
                              ></div>
                            ) : ('')}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(null, {
  fetchCompletedUnitsToday,
  fetchTimeSettings,
  fetchPlanSettings,
  fetchActiveUnits,
})(Home);
