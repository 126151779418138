import { useState } from "react";
import Container from "@mui/material/Container";
import { DataGrid, GridColDef, GridRowsProp, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import NoteAdd from '@mui/icons-material/NoteAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from "@mui/x-data-grid-generator";
import CloseIcon from "@mui/icons-material/Close";
import "./terjatve.css"

const columns = [
  { field: "dokument", headerName: "Dokument", width: 180 },
  { field: "name", headerName: "Stranka", width: 180, editable: true },
  {
    field: "dateCreated",
    headerName: "Datum zapadlosti",
    type: "date",
    width: 180,
  },
  {
    field: "znesek",
    headerName: "Znesek",
    width: 180,
    editable: true,
  },
  {
    field: "stranka_obvescena",
    headerName: "Stranka obveščena",
    type: "boolean",
    width: 100,
    editable: true,
  },
  {
    field: "opomin",
    headerName: "Opomin",
    type: "boolean",
    width: 100,
    editable: true,
  },
  {
    field: "opomin_i",
    headerName: "Opomin pred I",
    type: "boolean",
    width: 100,
    editable: true,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<NoteAdd />}
        label="NoteAdd"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Izbriši"

        showInMenu
      />,
      <GridActionsCellItem
        icon={<FileCopyIcon />}
        label="Dupliciraj"
        showInMenu
      />,
    ],
  },
];

const rows = [
  {
    dokument: "22-390-002401",
    id: 1,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-2.551,33 €",
    stranka_obvescena: "true",
  },
  {
    dokument: "22-390-002075",
    id: 2,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-345,87 €",
    stranka_obvescena: "true",
    opomin: "true",
  },
  {
    dokument: "22-390-002071",
    id: 3,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-2.849,83 €",
  },
  {
    dokument: "22-390-002355",
    id: 4,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-177,97 €",
  },
  {
    dokument: "222-390-002056",
    id: 5,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-5.487 €",
  },
  {
    dokument: "222-390-002056",
    id: 5,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-6.487 €",
  },
  {
    dokument: "222-390-002056",
    id: 6,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-1.287 €",
  },
  {
    dokument: "222-390-002051",
    id: 7,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-1.887 €",
  },
  {
    dokument: "222-390-002052",
    id: 8,
    name: randomTraderName(),
    dateCreated: randomCreatedDate(),
    znesek: "-1.387 €",
  },
];

const Terjatve = () => {
  const [selected, setSelected] = useState(false);

  const closeData = () => {
    setSelected(false);
  };

  const handleRowClick = () => {
    setSelected(true);
  };
  return (
    <Container
      maxWidth="lg"
      style={{
        height: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "left",
          marginTop: "50px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: "100px",
            height: "auto",
            marginRight: "20px",
          }}
          src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png"
        />
        <h1>Zapadle obveznosti</h1>
      </div>

      {selected !== false && (
        <Card className="card">
          <CardContent className="card-content">
            <div className="row between">
              <div>
                <h3 className="card-title">
                  <strong>Opombe</strong>
                </h3>
                <br></br>
                <div>
                    <p>3.4.2023 - preveri // do 14.4 plača 100 € za razliko uredim dobropis</p>
                    <p>4.4.2023 -  pokliči in preveri <span className="tag">komercialist obveščen</span></p> 
                    <p>5.4.2023 - poslan opomin</p>
                </div>
              </div>
              
              <div className="row">
                <CloseIcon className="btn" onClick={closeData} />
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <div style={{ height: 500, width: "100%" }}>
        <DataGrid rows={rows} columns={columns} onRowClick={handleRowClick} slots={{ toolbar: GridToolbar }} />
      </div>
    </Container>
  );
};
export default Terjatve;
