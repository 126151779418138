import "./App.css";
import "./index.css";
import { Routes, Route, Navigate, redirect } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import HistoryPage from "./pages/HistoryPage";
import SettingsPage from "./pages/SettingsPage";
import Terjatve from "./pages/terjatve";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DashboardPage />}></Route>
        <Route path="/dashboard" element={<HomePage />}></Route>
        <Route path="/history" element={<HistoryPage />}></Route>
        <Route path="/settings" element={<SettingsPage />}></Route>
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
        {/* <Route path="*" element={<Navigate to="/auth" />} /> */}
        <Route path="/terjatve" element={<Terjatve />}></Route>
      </Routes>
    </div>
  );
}

export default App;
