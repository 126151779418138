import { useEffect, useState } from "react";

import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Moment from "moment";
import "moment/locale/sl";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveUnits,
  selectUnitBySn,
  fetchCompletedUnitsToday,
  fetchCompletedUnitsWeek,
} from "../../store/redux/slices/unitsSlice";
import "./Dashboard.css";
import "../../App.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const Dashboard = () => {
  const [data, setdata] = useState([]);
  const [selected, setSelected] = useState(false);
  const [unit, setUnit] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState({});

  const units = useSelector((state) => state.units.units);
  const unitStatus = useSelector((state) => state.units.status);
  const todayCompleted = useSelector((state) => state.units.todayCompleted);
  const weekCompleted = useSelector((state) => state.units.weekCompleted);
  const dispatch = useDispatch();

  useEffect(() => {
    //fetchActiveStages();
    if (unitStatus === "idle") {
      dispatch(fetchActiveUnits());
    }
    dispatch(fetchCompletedUnitsToday());
    dispatch(fetchCompletedUnitsWeek());
  }, [
    unitStatus,
    dispatch,
    units,
    fetchCompletedUnitsToday,
    fetchCompletedUnitsWeek,
  ]);

  const closeData = () => {
    //setSelectedPhase({});
    setSelected(false);
  };

  function getPhases(params) {
    Moment.locale("sl");

    return Moment(params.row.phases[0].startTime).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
  }

  const setWorkplace = (workplace) => {
    var dm = "";

    if (workplace === 1 || workplace === 2) {
      dm = "DM1";
    } else if (workplace === 3 || workplace === 4) {
      dm = "DM2";
    } else if (workplace === 5 || workplace === 6) {
      dm = "DM3";
    } else if (workplace === 7 || workplace === 8) {
      dm = "DM4";
    } else if (workplace === 9 || workplace === 10) {
      dm = "DM5";
    }

    return dm;
  };

  const getWorkplace = (params) => {
    var dm = "";

    if (
      params.row.currentWorkplace === 1 ||
      params.row.currentWorkplace === 2
    ) {
      dm = "DM1";
    } else if (
      params.row.currentWorkplace === 3 ||
      params.row.currentWorkplace === 4
    ) {
      dm = "DM2";
    } else if (
      params.row.currentWorkplace === 5 ||
      params.row.currentWorkplace === 6
    ) {
      dm = "DM3";
    } else if (
      params.row.currentWorkplace === 7 ||
      params.row.currentWorkplace === 8
    ) {
      dm = "DM4";
    } else if (
      params.row.currentWorkplace === 9 ||
      params.row.currentWorkplace === 10
    ) {
      dm = "DM5";
    }

    return dm;
  };

  const columns = [
    {
      field: "enota",
      headerName: "Enota",
      width: 400,
    },
    {
      field: "sn",
      headerName: "Serijska številka",
      width: 150,
    },
    {
      field: "dn",
      headerName: "Delovni nalog",
      width: 130,
    },
    {
      field: "phases",
      headerName: "Začetni čas",
      width: 250,
      valueGetter: getPhases,
    },
    {
      field: "workplace",
      headerName: "Delovno mesto",
      width: 150,
      valueGetter: getWorkplace,
    },
  ];

  const handleRowClick = (params) => {
    //getSelectedPhase(params.row.sn, params.row.dn);

    units.map((unit) => {
      if (unit.sn === params.row.sn) {
        setUnit(unit);
      }
    });
    setSelected(true);
  };

  return (
    <div className="content">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card className="card card-body">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              color="text.secondary"
              gutterBottom
            >
              Nedokončane enota
            </Typography>
            <span>{units.length}</span>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="card card-body">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              color="text.secondary"
              gutterBottom
            >
              Število izdelanih enot na dnevni ravni
            </Typography>
            <span>{todayCompleted}</span>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="card card-body">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              color="text.secondary"
              gutterBottom
            >
              Število izdelanih enot na tedenski ravni
            </Typography>
            <span>{weekCompleted}</span>
          </Card>
        </Grid>

        {selected !== false && (
          <Grid item xs={12}>
            <Card className="card">
              <CardContent className="card-content">
                <div className="row between">
                  <div>
                    <h3 className="card-title">
                      <strong>{unit.enota}</strong>
                    </h3>
                    <h4 className="card-subtitle">
                      {unit.dn}/{unit.sn}
                    </h4>
                  </div>
                  <div className="row">
                    <CloseIcon className="btn" onClick={closeData} />
                  </div>
                </div>
              </CardContent>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="bg-grey">
                    <TableRow>
                      <TableCell>
                        <strong>Delovno mesto</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Začetni čas</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Končni čas</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Opomba</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {unit.phases.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "& .cold": {
                            backgroundColor: "#b9d5ff91",
                            color: "#1a3e72",
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {setWorkplace(row.workplace)}
                        </TableCell>
                        <TableCell>
                          {Moment(row.startTime).format(
                            "Do MMMM YYYY, HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          {row.endTime !== null
                            ? Moment(row.endTime).format(
                                "Do MMMM YYYY, HH:mm:ss"
                              )
                            : "V teku"}
                        </TableCell>
                        <TableCell>{row.note ? row.note : "/"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card className="card">
            <CardContent className="card-header" style={{ textAlign: "left" }}>
              <h3 className="card-title">
                <strong> ENOTE V IZDELAVI</strong>
              </h3>
            </CardContent>
            <Box
              sx={{
                height: 400,
                width: "100%",
                "& .late": {
                  backgroundColor: "#ff943975",
                  color: "#1a3e72",
                },
              }}
            >
              <DataGrid
                className="data-grid"
                rows={units}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                getRowId={(row) => row._id + 1}
                experimentalFeatures={{ newEditingApi: true }}
                onRowClick={handleRowClick}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default Dashboard;
