import { configureStore } from "@reduxjs/toolkit";
import unitsReducer from "./slices/unitsSlice";
import settingsReducer from "./slices/settingsSlice";
import msalInstanceReducer from './slices/msalInstanceSlice';

const store = configureStore({
  reducer: { units: unitsReducer, settings: settingsReducer, msalInstance: msalInstanceReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })

});

export default store;
