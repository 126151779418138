import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchTimeSettings,
  fetchPlanSettings,
  updatePlanSettings,
  updateTimeSettings,
} from "../../store/redux/slices/settingsSlice";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";


const Settings = () => {
  const timeSettings = useSelector((state) => state.settings.timeSettings);
  const planSettings = useSelector((state) => state.settings.planSettings);
  const settingsStatus = useSelector((state) => state.settings.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (settingsStatus === "idle") {
      dispatch(fetchTimeSettings());
      dispatch(fetchPlanSettings());
    }
  });

  const onPlanSettingsChange = async (event) => {

    let payload = {
      plan: event.target.value,
      type: event.target.id
    };

    try {
      await dispatch(updatePlanSettings(payload)).unwrap();
    } catch (err) {
      console.log("error");
      console.error("Failed to save the unit: ", err);
    }
  };

  const onChangeValue = async (event) => {
    const dm = parseInt(event.target.id);
    const time = parseInt(event.target.value);

    try {
      await dispatch(updateTimeSettings({ dm, time })).unwrap();
    } catch (err) {
      console.log("error");
      console.error("Failed to save the unit: ", err);
    }

    console.log(event.target.value);
    console.log(event.target.id);
  };

  return (
    <div className="content">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card className="card card-body">
            <Typography
              sx={{ fontSize: 14, fontWeight: "bold" }}
              color="text.secondary"
              gutterBottom
            >
              Predvideni časi operacij na posameznih DM
            </Typography>

            <CardContent>
              {timeSettings.map((item) => (
                <div
                  key={item._id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0",
                  }}
                >
                  <div>DM {item.dm}</div>
                  <div>
                    <TextField
                      id={item.dm.toString()}
                      label="Čas (min)"
                      variant="outlined"
                      defaultValue={item.time}
                      onChange={onChangeValue}
                    />
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className="card card-body">
            <Typography
              sx={{ fontSize: 14, fontWeight: "bold" }}
              color="text.secondary"
              gutterBottom
            >
              Dnevni in tedenski plan
            </Typography>

            <CardContent>
              {planSettings.map((item) => (
                <div
                  key={item.plan}
                  style={{
                    padding: "10px 0",
                  }}
                >
                  <div
                    style={{
                      padding: "0 0 15px 0",
                    }}
                  >
                    {item.name}
                  </div>
                  <div>
                    <TextField
                      id={item.type}
                      label="Število enot"
                      variant="outlined"
                      defaultValue={item.plan}
                      onChange={onPlanSettingsChange}
                    />
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default connect(null, { updateTimeSettings, fetchTimeSettings })(Settings);
