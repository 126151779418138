import { useEffect, useState } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";

import {
  setMsalInstance,
  setAuthStatus,
  setUser,
} from "./store/redux/slices/msalInstanceSlice";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";

import "../src/App.css";
import classes from "../src/Auth.css";


const Auth = ({ children, msalInstance }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authStatus = useSelector((state) => state.msalInstance.isAuth);
  const user = useSelector((state) => state.msalInstance.user);
  const msal = useSelector((state) => state.msalInstance.msalInstance);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      dispatch(setAuthStatus({ authStatus: true }));
      dispatch(setUser(accounts[0]));
      dispatch(setMsalInstance(msalInstance));
    }
  }, [dispatch]);

  const login = async () => {
    try {
      await msalInstance.loginPopup();
      const accounts = msalInstance.getAllAccounts();
      dispatch(setAuthStatus({ authStatus: true }));
      dispatch(setAuthStatus(accounts[0]));

      msalInstance.loginRedirect();
    } catch (error) {
      setErrorMessage("An error occurred during login.");
      console.error(error);
    }
  };

  const logout = () => {
    msalInstance.logoutPopup();

    dispatch(setUser(null));
    dispatch(setAuthStatus({ authStatus: false }));
  };

  return (
    <MsalProvider instance={msalInstance}>
      {authStatus ? (
        <>{children}</>
      ) : (
        <>
          <Container
            maxWidth="sm"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card style={{ padding: "20px" }}>
            <section>
              <div className="text-center">
                <img className="logo" src="https://www.termoshop.si/wp-content/uploads/2021/07/termoshop-30-let.png" />
              </div>
              <button className="btn-primary btn" onClick={login}>Prijava</button>
              {errorMessage && <p>{errorMessage}</p>}
            </section>
            </Card>
           
          </Container>    
        </>
      )}
    </MsalProvider>
  );
};

export default Auth;
