import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "../dashboard/DashboardSidebar.css";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useDispatch, useSelector } from 'react-redux';
import { setMsalInstance, setAuthStatus, setUser } from "../../store/redux/slices/msalInstanceSlice";

const DashboardSiderbar = () => {

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.msalInstance.isAuth);
  const user = useSelector((state) => state.msalInstance.user);
  const msalInstance = useSelector((state) => state.msalInstance.msalInstance);

  const logout = () => {
    //msalInstance.logoutPopup();
    msalInstance.logout();

    dispatch(setUser(null));
    dispatch(setAuthStatus({ authStatus: false }));
  };

  return (
    <div className="sidebar flex-none">
      <div className="text-center">
        <img
          className="logo m-auto mt-4 mb-5"
          alt="logo"
          src="termoshop-30-let.png"
        />
      </div>

      <nav>
        <div className="mt-5 mb-5">
          <p className="c-white text-left">Pozdravljen,<br/> 
          {user && user.username}</p>
        </div>
       
        <ul>
          
            <li className={splitLocation[1] === "" ? "active" : ""}>
              <DashboardIcon sx={{ color: "white" }} />{" "}
              <Link to="/">Dashboard</Link>
            </li>
 
    
            <li className={splitLocation[1] === "history" ? "active" : ""}>
              <HistoryIcon sx={{ color: "white" }} />{" "}
              <Link to="/history">Zgodovina</Link>
            </li>
      
      
            <li className={splitLocation[1] === "settings" ? "active" : ""}>
              <AccountCircleIcon sx={{ color: "white" }} />{" "}
              <Link to="/settings">Nastavitve</Link>
            </li>
       
      
            <li style={{marginTop: "30px"}}>
               <PrecisionManufacturingIcon sx={{ color: "white" }} />{" "}
               <Link to="/dashboard">Proizvodnja</Link>
            </li>

            <li style={{marginTop: "30px"}}>
               <LogoutIcon sx={{ color: "white" }} />{" "}
               <button onClick={logout}>Odjava</button>

            </li>
 
        </ul>
      </nav>
    </div>
  );
};
export default DashboardSiderbar;
